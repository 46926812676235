body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none;
}

.p-chip.custom-chip-success {
  background: #689F38;
  color: #fff;
}
.p-chip.custom-chip-danger {
  background: #D32F2F;
  color: #fff;
}
.p-chip.custom-chip-warning {
  background: #FBC02D;
  color: #212529;
}
.text-danger{
  color:#D32F2F;
}
.text-success{
  color:#689F38;
}